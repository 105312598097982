import Vue from 'vue';
import Vuex from 'vuex';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {

    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {
    }
});
