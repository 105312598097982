<template>
    <div class="wrapper">
        <h1>Sneezr</h1>
        <h2>{{ formattedTime }}</h2>

        <div class="button-grid">
            <div v-for="person in people">
                <button :style="{ '--person-color': person.color }" @click="sendEntry( person )">{{ person.name }}</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .button-grid{
        display:flex;
        flex-wrap:wrap;
        padding:0.1px;

        &>div{
            flex-basis:50%;
            text-align:center;
        }

        button{
            padding:1rem 2rem;
            min-width:40vw;
            margin-bottom:1rem;

            font-weight:bold;
            font-size:1.5rem;

            border:2px solid var( --person-color );
            color:black;

            background-color:var( --person-color );
        }
    }
</style>

<script>
    import moment from 'moment';
    import EntryProxy from '@/app/proxies/EntryProxy';

    export default {
        data()
        {
            return {
                people: [
                    { name: 'Bob', color: '#53292a' },
                    { name: 'Daisy', color: '#5c4e88' },
                    { name: 'David', color: 'cyan' },
                    { name: 'Heber', color: '#00ff1f' },
                    { name: 'Krista', color: '#ccc' },
                    { name: 'Linna', color: '#1D3EFF' },
                    { name: 'Michel', color: '#c74a02' },
                    { name: 'Sven', color: '#10b661' },
                    { name: 'Ting', color: '#FF00FF' }
                ],

                timeFocus: false,
                formattedTime: moment().format( 'YYYY-MM-DDTHH:mm:ss' ),
                userChangedTime: false
            };
        },

        computed: {
            normalTime()
            {
                return moment( this.formattedTime ).unix();
            }
        },

        mounted()
        {
            this.timeInterval = setInterval( () =>
            {
                if( !this.timeFocus )
                {
                    this.formattedTime = moment().format( 'YYYY-MM-DDTHH:mm:ss' );
                }
            }, 1000 );
        },

        methods: {
            onTimeFocus()
            {
                this.timeFocus = true;
                this.userChangedTime = true;
            },

            onTimeBlur()
            {
                this.timeFocus = false;
            },

            sendEntry( pPerson )
            {
                let data = {
                    name: pPerson.name,
                    sneezed_at: moment.unix( this.normalTime ).format( 'YYYY-MM-DD HH:mm:ss' ),
                    intensity: 5
                };

                return new EntryProxy()
                    .create( data )
                    .then( response =>
                    {
                        console.log( response );
                        // context.dispatch( 'getAll' );
                    })
                    .catch( err =>
                    {
                        console.warn( 'turf create error', err );
                    });
            }
        }
    };
</script>
